<template functional>
	<div class="component c-championships c-championships--list">
		<transition-group
			name="list"
			tag="ul"
			class="c-championships__list reset-list row"
			v-if="props.data && props.data.length"
		>
			<li
				class="c-championships__item col-1-of-1
					bp-540:col-1-of-2 bp-540:padding-16 bp-1024:col-1-of-3 bp-1440:col-1-of-4"
				:class="{
					'c-championships__last': index === props.data.length - 1,
				}"
				v-for="(championship, index) in props.data"
				:key="championship.id"
			>
				<component
					:is="injections.components.ChampionshipCard"
					:data="championship"
					:show-tile-overlay-text="props.showTileOverlayText"
				/>
			</li>
			<li
				class="c-championships__all col-1-of-1 bp-540:col-1-of-2
					bp-540:padding-16 bp-1024:col-1-of-3 bp-1440:col-1-of-4"
				:key="'championships_all'"
				v-if="
					props.route_name &&
						props.showAllChampionshipsLink &&
						(props.route_name === 'Home' ||
							props.route_name === 'Missing')
				"
			>
				<router-link
					class="c-championships__all-url display-block text-decoration-none"
					to="/championships"
				>
					<div
						class="display-flex
							bp-540:display-block"
					>
						<div
							class="c-championships__all-media bg-color-primary position-relative
								bp-540:overflow-hidden bp-540:aspect-ratio-16-9"
						>
							<div
								class="c-championships__all-icon bg-color-white border-radius-rounded position-absolute utility-position-center text-brand"
							>
								<component
									class="c-championships__all-plus position-absolute left-50 top-50 transition-duration-15"
									:is="injections.components.PlusIcon"
									size="2x"
								/>
								<component
									class="c-championships__all-chevron position-absolute left-50 top-50 transition-duration-15"
									:is="injections.components.ChevronRightIcon"
									size="2x"
								/>
							</div>
						</div>
						<div
							class="c-championships__all-details padding-16 display-flex
									bp-540:display-block bp-768:padding-x-0"
						>
							<div
								class="c-championships__all-details-content display-flex align-items-center"
							>
								<!-- <svg
									class="margin-r-8 flex-shrink-0"
									width="21"
									height="21"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clip-path="url(#clip0)">
										<path
											d="M14.106 6.443l.707-.707-.707.707zm3.713 0l.707-.707a1 1 0 00-1.415 0l.707.707zm-3.713-3.712l.707.707a1 1 0 000-1.414l-.707.707zM12.25.875l.707-.707a1 1 0 00-1.414 0l.707.707zM1.113 12.012l-.707-.707a1 1 0 000 1.414l.707-.707zm1.856 1.856l-.707.707a1 1 0 001.414 0l-.707-.707zm3.713 3.712l-.708-.707a1 1 0 000 1.415l.708-.708zm1.856 1.857l-.707.707a1 1 0 001.414 0l-.707-.707zM19.675 8.3l.707.707a1 1 0 000-1.414l-.707.707zm-6.276-1.15a3.625 3.625 0 005.127 0L17.11 5.737a1.625 1.625 0 01-2.298 0L13.4 7.151zm0-5.126a3.625 3.625 0 000 5.127l1.414-1.415a1.625 1.625 0 010-2.298L13.4 2.024zm-1.856-.442l1.856 1.856 1.414-1.414L12.957.168l-1.414 1.414zM1.82 12.72L12.957 1.582 11.543.168.406 11.305l1.414 1.414zm1.856.442L1.82 11.305.406 12.719l1.856 1.856 1.414-1.414zm0 1.414a1.625 1.625 0 012.298 0l1.415-1.414a3.625 3.625 0 00-5.127 0l1.414 1.414zm2.298 0a1.625 1.625 0 010 2.298l1.415 1.415a3.625 3.625 0 000-5.127l-1.415 1.414zm3.27 4.154L7.39 16.873l-1.415 1.415 1.857 1.856 1.414-1.415zm9.724-11.136L7.83 18.729l1.414 1.415L20.382 9.007l-1.415-1.414zM17.11 7.15l1.857 1.856 1.414-1.414-1.856-1.857-1.415 1.415z"
											fill="#0a6fac"
										/>
										<path
											d="M9.625 7.875L12.1 10.35"
											stroke="#0a6fac"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</g>
									<defs>
										<clipPath id="clip0">
											<path fill="#fff" d="M0 0h21v21H0z" />
										</clipPath>
									</defs>
								</svg> -->
								<img
									src="@/assets/tickets-v2.svg"
									width="30"
									height="30"
									alt=""
									style="filter: invert(36%) sepia(99%) saturate(894%) hue-rotate(169deg) brightness(94%) contrast(104%);"
								/>
								<!-- #0088ce == filter: invert(36%) sepia(99%) saturate(894%) hue-rotate(169deg) brightness(94%) contrast(104%); -->
								<span
									class="header-font font-size-18 line-height-100 text-transform-uppercase text-brand bp-1024:font-size-30"
									>All NCAA Championship Tickets</span
								>
							</div>
						</div>
					</div>
				</router-link>
			</li>
		</transition-group>
		<div
			class="padding-x-32 padding-b-32 padding-t-16 bp-1024:padding-b-24"
			v-else
		>
			There are currently no championships available.
		</div>
	</div>
</template>

<script>
import { PlusIcon, ChevronRightIcon } from 'vue-feather-icons';
import ChampionshipCard from './ChampionshipCard';

export default {
	name: 'ChampionshipsList',

	inject: {
		components: {
			default: { ChampionshipCard, PlusIcon, ChevronRightIcon },
		},
	},

	props: {
		data: {
			required: true,
			type: Array,
			default: () => [],
		},
		route_name: {
			type: String,
			default: null,
		},
		showTileOverlayText: {
			required: false,
			type: Boolean,
			default: false,
		},
		showAllChampionshipsLink: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.c-championships__all {
	&:hover,
	&:focus {
		.c-championships__all-chevron {
			transform: translate(-50%, -50%);
		}
		.c-championships__all-plus {
			transform: translate(100%, -50%);
			opacity: 0;
		}
	}
	&-icon {
		height: 56px;
		width: 56px;
	}
	&-chevron {
		transform: translate(-200%, -50%);
	}
	&-plus {
		transform: translate(-50%, -50%);
		transition: opacity 0.1s;
	}
}
</style>

<style lang="scss">
.c-championships--list {
	.c-championships__item {
		transition: all 0.5s;

		@media screen and (max-width: 539px) {
			padding-top: 8px;
			background-color: #e5e5e5;
		}
	}

	.c-championships__last {
		@media screen and (max-width: 539px) {
			padding-bottom: 8px;
		}
	}

	.c-championships__all {
		@media screen and (max-width: 539px) {
			padding-bottom: 8px;
			background-color: #e5e5e5;
		}

		&-media {
			@media screen and (max-width: 539px) {
				width: 100px;
				height: 100px;
			}
		}

		&-details {
			@media screen and (max-width: 539px) {
				background-color: #f6f8fa;
				flex-grow: 1;
			}

			&-content {
				@media screen and (max-width: 379px) {
					// The size of the ticket+text is compressing the image
					max-width: 180px;
				}
			}
		}
	}

	.list-enter,
	.list-leave-to {
		opacity: 0;
		transform: translateY(36px);
	}

	.list-leave-active {
		position: absolute;
		display: none;
	}
}
</style>

<template functional>
	<div
		class="component c-championship c-championship--card"
		v-if="props.data"
	>
		<component
			:is="injections.components.NavigationLink"
			:_class="'c-championship__url display-block text-decoration-none'"
			:url="
				props.data.full_page
					? `/championship/${props.data.slug}`
					: $options.primary_link(props.data.primary_link)
			"
		>
			<div
				class="display-flex
					bp-540:display-block"
			>
				<div
					class="c-championship__media bg-color-light-background position-relative
						bp-540:aspect-ratio-16-9 bp-540:overflow-hidden"
				>
					<img
						v-lazy="$options.get_image_resizer_url(`https://d230gea5qwdu36.cloudfront.net${props.data.image.url}`)"
						:alt="props.data.image.alt_text"
						class="c-championship__image height-100 position-absolute top-0 left-0 object-fit-cover object-position-c transition width-100"
						v-if="props.data.image && props.data.image.url"
					/>
					<img
						class="c-championship__image height-100 position-absolute top-0 left-0 object-fit-cover object-position-c transition width-100"
						src="@/assets/placeholder.jpg"
						alt=""
						v-else
					/>
					<div
						class="c-championship__division display-inline-block font-size-10 text-on-primary position-absolute text-transform-uppercase"
					>
						{{ props.data.division }}
					</div>
				</div>
				<div
					class="c-championship__details padding-16 display-flex
						bp-768:padding-x-0 bp-540:display-block"
				>
					<div
						class="c-championship__title display-flex align-items-center"
					>
						<img
							src="@/assets/tickets-v2.svg"
							width="30"
							height="30"
							alt=""
							style="filter: invert(36%) sepia(99%) saturate(894%) hue-rotate(169deg) brightness(94%) contrast(104%);"
						/>
						<span
							class="flex-item-1 header-font font-size-18 line-height-100 text-transform-uppercase text-brand transition transition-timing-function-ease
								bp-1024:font-size-30"
							>{{ props.data.title }}</span
						>
					</div>
				</div>
			</div>
		</component>
		<div
			v-if="
				props.showTileOverlayText &&
					props.data.tile_overlay_links &&
					props.data.tile_overlay_links.length > 0
			"
			class="tileOverlayTextDesktop"
		>
			<div class="tileOverlayTextWrapper">
				<component
					v-for="link in props.data.tile_overlay_links"
					v-bind:key="link.id"
					:is="injections.components.NavigationLink"
					_class="c-championship__url display-block text-decoration-none tileOverlayTextLink"
					:url="link.link"
					_external-icon
				>
					<span
						v-html="
							link.title.replaceAll(/(?:\r\n|\r|\n)/g, '<br />')
						"
					></span>
				</component>
			</div>
		</div>
		<div
			v-if="
				props.showTileOverlayText &&
					props.data.tile_overlay_links &&
					props.data.tile_overlay_links.length > 0
			"
			class="tileOverlayTextMobile c-championship__tileOverlayTextMobile"
			style="position: relative; visibility: visible"
		>
			<div class="tileOverlayTextWrapper">
				<component
					v-for="link in props.data.tile_overlay_links"
					v-bind:key="link.id"
					:is="injections.components.NavigationLink"
					_class="c-championship__url display-block text-decoration-none tileOverlayTextLink"
					:url="link.link"
					_external-icon
				>
					<span
						v-html="
							link.title.replaceAll(/(?:\r\n|\r|\n)/g, '<br />')
						"
					></span>
				</component>
			</div>
		</div>
	</div>
</template>

<script>
import NavigationLink from '@/components/common/NavigationLink';
import { get_image_resizer_url } from '@/helpers';

export default {
	name: 'ChampionshipCard',

	inject: {
		components: {
			default: { NavigationLink },
		},
	},

	props: {
		data: {
			required: true,
			type: Object,
			default: null,
		},
		showTileOverlayText: {
			required: false,
			type: Boolean,
			default: false,
		},
	},

	primary_link(primary_link) {
		if (primary_link) {
			return primary_link;
		}

		return '';
	},

  get_image_resizer_url(image_url) {
    return get_image_resizer_url(image_url);
  },
};
</script>

<style lang="css">
.tileOverlayTextDesktop,
.tileOverlayTextMobile {
	visibility: hidden;
	width: 100%;
	display: flex;
	position: absolute;
	color: white;
	opacity: 0.95;
	bottom: 0;
}
.tileOverlayTextDesktop {
	aspect-ratio: 16/9;
	top: 0;
	bottom: unset;
	pointer-events: none;
	align-items: flex-end;
}
.tileOverlayTextWrapper {
	width: 100%;
	text-align: left;
	background-color: #005eb8;
	pointer-events: all;
}
.tileOverlayTextLink {
	color: white;
	text-decoration: none;
	padding: 8px;
	border-top: 1px solid #fff;
}
.tileOverlayTextLink:before {
	content: '\203A';
	margin-right: 8px;
}
</style>

<style lang="scss">
.c-championship--card {
	position: relative;
	&:hover,
	&:focus {
		.c-championship__image {
			transform: scale(1.1);
		}

		.c-championship__title > span {
			color: var(--color-primary-hover);
		}
		.tileOverlayTextDesktop {
			visibility: visible !important;
			@media screen and (max-width: 539px) {
				visibility: hidden;
				display: none;
			}
		}
	}
	.c-championship {
		&__media {
			@media screen and (max-width: 539px) {
				width: 100px;
				height: 100px;
			}
		}
		//for naming
		&__url {
			&:hover,
			&:focus {
				.c-championship__image {
					transform: scale(1.1);
				}

				.c-championship__title > span {
					color: var(--color-primary-hover);
				}

				.tileOverlayTextDesktop {
					visibility: visible !important;
					@media screen and (max-width: 539px) {
						visibility: hidden;
						display: none;
					}
				}
			}
		}
		&__division {
			background: rgba($color: #000000, $alpha: 0.8);
			border-radius: 56px;
			height: 23px;
			line-height: 23px;
			padding: 0 16px;
			top: 16px;
			left: 16px;

			@media screen and (max-width: 539px) {
				top: 4px;
				left: 4px;
			}
		}

		&__details {
			@media screen and (max-width: 539px) {
				background-color: #f6f8fa;
				flex-grow: 1;
			}
		}

		&__tileOverlayTextMobile {
			@media screen and (min-width: 539px) {
				visibility: hidden;
				display: none;
			}
		}

		&__title {
			@media screen and (max-width: 379px) {
				// The size of the ticket+text is compressing the image
				max-width: 180px;
			}
		}
	}
}
</style>

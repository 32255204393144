var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"component c-championships c-championships--list"},[(_vm.props.data && _vm.props.data.length)?_c('transition-group',{staticClass:"c-championships__list reset-list row",attrs:{"name":"list","tag":"ul"}},[_vm._l((_vm.props.data),function(championship,index){return _c('li',{key:championship.id,staticClass:"c-championships__item col-1-of-1\n\t\t\t\tbp-540:col-1-of-2 bp-540:padding-16 bp-1024:col-1-of-3 bp-1440:col-1-of-4",class:{
				'c-championships__last': index === _vm.props.data.length - 1,
			}},[_c(_vm.injections.components.ChampionshipCard,{tag:"component",attrs:{"data":championship,"show-tile-overlay-text":_vm.props.showTileOverlayText}})],1)}),(
				_vm.props.route_name &&
					_vm.props.showAllChampionshipsLink &&
					(_vm.props.route_name === 'Home' ||
						_vm.props.route_name === 'Missing')
			)?_c('li',{key:'championships_all',staticClass:"c-championships__all col-1-of-1 bp-540:col-1-of-2\n\t\t\t\tbp-540:padding-16 bp-1024:col-1-of-3 bp-1440:col-1-of-4"},[_c('router-link',{staticClass:"c-championships__all-url display-block text-decoration-none",attrs:{"to":"/championships"}},[_c('div',{staticClass:"display-flex\n\t\t\t\t\t\tbp-540:display-block"},[_c('div',{staticClass:"c-championships__all-media bg-color-primary position-relative\n\t\t\t\t\t\t\tbp-540:overflow-hidden bp-540:aspect-ratio-16-9"},[_c('div',{staticClass:"c-championships__all-icon bg-color-white border-radius-rounded position-absolute utility-position-center text-brand"},[_c(_vm.injections.components.PlusIcon,{tag:"component",staticClass:"c-championships__all-plus position-absolute left-50 top-50 transition-duration-15",attrs:{"size":"2x"}}),_c(_vm.injections.components.ChevronRightIcon,{tag:"component",staticClass:"c-championships__all-chevron position-absolute left-50 top-50 transition-duration-15",attrs:{"size":"2x"}})],1)]),_c('div',{staticClass:"c-championships__all-details padding-16 display-flex\n\t\t\t\t\t\t\t\tbp-540:display-block bp-768:padding-x-0"},[_c('div',{staticClass:"c-championships__all-details-content display-flex align-items-center"},[_c('img',{staticStyle:{"filter":"invert(36%) sepia(99%) saturate(894%) hue-rotate(169deg) brightness(94%) contrast(104%)"},attrs:{"src":require("@/assets/tickets-v2.svg"),"width":"30","height":"30","alt":""}}),_c('span',{staticClass:"header-font font-size-18 line-height-100 text-transform-uppercase text-brand bp-1024:font-size-30"},[_vm._v("All NCAA Championship Tickets")])])])])])],1):_vm._e()],2):_c('div',{staticClass:"padding-x-32 padding-b-32 padding-t-16 bp-1024:padding-b-24"},[_vm._v(" There are currently no championships available. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }